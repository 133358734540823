import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76')
];

export const server_loads = [];

export const dictionary = {
		"/": [15],
		"/admin/user": [16,[2]],
		"/api": [17,[3],[4]],
		"/api/open": [18,[3],[4]],
		"/api/open/reference": [19,[3],[4]],
		"/api/open/try": [20,[3],[4]],
		"/api/postman": [21,[3],[4]],
		"/api/reference": [22,[3],[4]],
		"/api/sdk/js": [23,[3],[4]],
		"/api/status": [24,[3],[4]],
		"/api/try": [25,[3],[4]],
		"/excel": [26,[5],[6]],
		"/excel/authentication": [27,[5],[6]],
		"/excel/installation": [28,[5],[6]],
		"/excel/queue": [29,[5],[6]],
		"/excel/search": [30,[5],[6]],
		"/login/callback": [31],
		"/me/api-key": [32,[7],[8]],
		"/me/billing": [33,[7],[8]],
		"/me/history": [34,[7],[8]],
		"/me/list": [35,[7],[8]],
		"/me/list/[listId]": [36,[7],[8]],
		"/me/notification": [37,[7],[8]],
		"/me/profile": [38,[7],[8]],
		"/me/subscription": [39,[7],[8]],
		"/me/usage": [40,[7],[8]],
		"/office": [41,[9],[10]],
		"/office/bulk": [43,[9],[10]],
		"/office/preference": [44,[9],[10]],
		"/office/[taxId]": [~42,[9],[10]],
		"/subscription": [45,[11],[12]],
		"/subscription/about": [46,[11],[12]],
		"/subscription/checkout": [47,[11],[12]],
		"/subscription/checkout/[subscriptionId]": [48,[11],[12]],
		"/subscription/credit": [49,[11],[12]],
		"/subscription/estimate": [50,[11],[12]],
		"/subscription/faq": [51,[11],[12]],
		"/subscription/privacy": [52,[11],[12]],
		"/subscription/tos": [53,[11],[12]],
		"/ui/anchor": [54,[13],[14]],
		"/ui/button": [55,[13],[14]],
		"/ui/cache": [56,[13],[14]],
		"/ui/card": [57,[13],[14]],
		"/ui/chart": [58,[13],[14]],
		"/ui/checkbox": [59,[13],[14]],
		"/ui/code": [60,[13],[14]],
		"/ui/copy": [61,[13],[14]],
		"/ui/credit": [62,[13],[14]],
		"/ui/divider": [63,[13],[14]],
		"/ui/form": [64,[13],[14]],
		"/ui/info": [65,[13],[14]],
		"/ui/input-chip": [67,[13],[14]],
		"/ui/input": [66,[13],[14]],
		"/ui/list": [68,[13],[14]],
		"/ui/notice": [69,[13],[14]],
		"/ui/paragraph": [70,[13],[14]],
		"/ui/select": [71,[13],[14]],
		"/ui/status": [72,[13],[14]],
		"/ui/stepper": [73,[13],[14]],
		"/ui/table": [74,[13],[14]],
		"/ui/text-area": [75,[13],[14]],
		"/ui/tooltip": [76,[13],[14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';